import React from 'react';

const Home = () => {
  // Handle the logout action (if any logic is needed)
  
  return (
    <div>
      <iframe
        src={`https://playafi.carrd.co`}
        style={{ width: '100%', height: '100vh', border: 'none' }}
        title="PlayaFi"
      ></iframe>
    </div>
  );
};

export default Home;